@font-face {
    font-family: 'booster-regular';
    src: url('./assets/font/booster_regular.ttf');
}

.App {
    text-align: center;
    min-height: 100vh;
    background-color: #EDF3FA;
    font-family: "booster-regular", serif;
    color: #001E62;
}

.App-logo {
    margin: 2rem;
    height: 15rem;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Semi-semi-official-tag {
    font-size: x-small;
}
